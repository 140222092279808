import React, {useEffect, useState} from 'react';
// import ReactDOM from "react-dom";
import { render } from 'react-dom';
import {Button, Layout, Row, Col, Input, message} from 'antd';
// import { AlignType } from 'rc-table/lib/interface'
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
// import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.min.css';
import './index.css';
import axios from "axios";
// import styles from "./index.module.css"
// import QRCode from 'react-qr-code'

const {Header} = Layout
const {TextArea} = Input

// const appURL = "http://localhost:8080"
// const appURL = "http://10.128.198.39:8080"
const appURL = "https://fourth-eon-330407.uc.r.appspot.com/"
// const appURL = "https://qr-api.panpili.com/"

function App(){

    // constructor(props)
    // {
    //     super(props)
    //
    //     this.state = {
    //         input_value: "",
    //         qr_value: "",
    //         visibility: "hidden"
    //     }
    //     this.inputText = React.createRef();
    //
    // }

    const [input, setInput] = useState("")
    const [qr, setQr] = useState("")
    const [qrDisplay, setQrDisplay] = useState("none")
    const inputRef = React.createRef()

    useEffect(()=> {
        const storedInput = localStorage.getItem("input")
        const storedQr = localStorage.getItem("qr")
        if (storedInput != null && storedInput !== "") {
            setInput(storedInput)
        }

        if (storedQr != null && storedQr !== "") {
            setQr(storedQr)
            setQrDisplay("")
        }
    }, [])

    function RenderInput(){
        return(
            <TextArea placeholder='Type something.' autoFocus
            ref={inputRef}
            value={input}
            onChange={(e) => {
                setInput(e.target.value)
                localStorage.setItem("input", e.target.value)
            }}
            style={{fontSize:'20px', height: "300px"}}/>
        )
    }

    // function RenderQR(){
    //     return(
    //         <div
    //             // style={{display:'flex', justifyContent:'center', alignItems:'stretch'}}
    //         >
    //             {/*<QRCode value={this.state.qr_value} style={{visibility: this.state.visibility}}/>*/}
    //         </div>
    //     )
    // }

    // renderRemoteQR(){
    //     render(
    //         <div>
    //
    //         </div>
    //     )
    // }

    function HandleClearText(){
        setInput("")
        setQr("")
        setQrDisplay("none")

        localStorage.removeItem("input")
        localStorage.removeItem("qr")

        // this.setState({input_value:"", qr_value:"", visibility:"hidden"})
        //
        // this.inputText.current.focus();
        // console.log(qrDisplay)
        // console.log("Text cleared!")
        inputRef.current.focus()
    }

    function handleGenerateQR()
    {
        // const text = this.state.input_value;
        // console.log(input)
        if(input.length > 0)
        {
            // console.log("length: " + text.length)
            const url = appURL
            const data = {
                "text": input,
                "size": 1000
            }

            // if (input === localStorage.getItem("input")) {
            //     // const storedQr = localStorage.getItem("qr")
            //     if (qr !== "" && visibility === "visible") {
            //         return
            //     }
            // }

            axios.post(url, data).then((rsp)=>{
                const data = rsp.data
                // console.log(data)
                const status = data.status
                if (status === 0) {
                    const qr = data.png
                    setQr(qr)
                    localStorage.setItem("qr", qr)
                    setQrDisplay("")
                } else if (status === 1) {
                    message.error("二维码生成出错了！")
                    setQr("")
                    localStorage.removeItem(qr)
                    setQrDisplay("none")
                }
                // message.info(qr)
            }).catch((_)=> {
                message.error("网络开小差了！")
            })
            // this.setState({qr_value:text, visibility: "visible"})
        }
        else
        {
            setQrDisplay("none")
            // this.setState({visibility: "hidden"})
        }
    }

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

            <Header style={{display:'flex'}}>
                <div style={{fontSize:'25px', color:'white'}}>Pili QR Generator</div>
            </Header>

            {/* up right down left */}
            <Row
                gutter={[0, 30]}
                style={{padding:"50px 0 0 0"}}
                // justify={"center"}
            >
                {/* input */}
                <Col
                    xs={{span:22, offset: 1}}
                    sm={{span:22, offset: 1}}
                    lg={{span:12, offset: 2}}
                    xl={{span:12, offset: 3}}
                >
                        {RenderInput()}
                </Col>
                {/* qr */}
                <Col
                    xs={{span: 20, offset: 2}}
                    sm={{span: 20, offset: 2}}
                    lg={{span: 8, offset:0}}
                    xl={{span: 6, offset:0}}
                >
                    <Row justify={"center"}>
                        <img
                            style={{display: qrDisplay}}
                            src={qr}
                            alt={""}
                            width={"300px"}
                            // className={styles.center}
                        />
                    </Row>
                </Col>
            </Row>

            <Row
                style={{padding:'30px 0 50px 0'}}
                // gutter={[{xs: 30, sm: 30, lg: 50, xl: 60}, 0]}
                // justify={"center"}
            >
                <Col
                    xs={{span:22, offset: 1}}
                    sm={{span:22, offset: 1}}
                    lg={{span:12, offset: 2}}
                    xl={{span:12, offset: 3}}
                >
                    <Row justify={"center"}
                         gutter={[{xs: 20, sm: 20, lg: 20, xl: 20}, 0]}
                    >
                        <Col>
                            <Button size='large' onClick={() => HandleClearText()}
                            >
                                Clear
                            </Button>
                        </Col>
                        <Col>
                            <Button size='large' onClick={() => handleGenerateQR()}>Get QR Code</Button>
                        </Col>
                    </Row>
                </Col>
                {/*<Col*/}
                {/*    style={{padding:'0 30px 0 0'}}*/}
                {/*    align={'right'}*/}
                {/*     xs={{span: 6, offset: 5}}*/}
                {/*     sm={{span: 6, offset: 5}}*/}
                {/*     lg={{span: 6, offset: 2}}*/}
                {/*     xl={{span: 6, offset: 3}}*/}
                {/*>*/}
                {/*    <Button size='large' onClick={() => handleClearText()}>Clear</Button>*/}
                {/*</Col>*/}

                {/*<Col*/}
                {/*    align={'left'}*/}
                {/*    xs={{span: 6, offset: 0}}*/}
                {/*    sm={{span: 6, offset: 0}}*/}
                {/*    lg={{span: 6, offset: 0}}*/}
                {/*    xl={{span: 6, offset: 0}}*/}
                {/*>*/}
                {/*    <Button size='large' onClick={() => handleGenerateQR()}>Get QR Code</Button>*/}
                {/*</Col>*/}
            </Row>
        </>
    )
}

render(<App/>, document.getElementById('root'))